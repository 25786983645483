.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 100vh;
    height: 90vh;
    margin-right: 10vw;
    margin-left: 10vw;
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.logo {
    border-radius: 100%;
    height: 30vh;
    margin-bottom: 60px;
}

.social_login {
    display: flex;
    flex-direction: column;
    min-height: 15vh;
    max-height: 15vh;
}

.login_section {
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}