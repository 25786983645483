.container,
.container_shimmer {
    min-height: 300px;
    max-height: 300px;
    min-width: var(--card-width);
    max-width: var(--card-width);
    border-radius: 20px;
    padding: 3vh;
    display: flex;
    flex-direction: column;
    background-color: white;
    transition: all 500ms;
}

.container_shimmer {
    background-color: #f0f0f0;
    /* Light gray background for the shimmer */
    position: relative;
    overflow: hidden;
    /* Optional: Add some shadow for depth */

    /* Adding the shimmer effect */
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        height: 100%;
        width: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.1) 100%);
        animation: shimmer 1.5s infinite;
    }
}

@keyframes shimmer {
    0% {
        left: -100%;
    }

    100% {
        left: 100%;
    }
}

.header {
    height: 20%;
}

.img {
    max-width: 60px;
    min-width: 60px;
    margin-bottom: 2vh;
    min-height: 60px;
}

.body {

    display: flex;
    align-items: center;
    flex: 1;
}

.action_pill {
    cursor: pointer;
    border-radius: 20px;
    background-color: black;
    color: white;
    font-weight: bolder;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}