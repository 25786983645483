.container {
    height: 100vh;
    padding: 40px;
}

.name_container {
    display: flex;
    gap: 10px;
    align-items: center;
}

.container_loading {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.details_section {
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.header_actions {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.spacer {
    width: 20px;
}

.progress_secition {
    display: flex;
    flex-direction: row;
}

.chart_container_right,
.chart_container {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 30px;
}

.chart_container_right {
    background-color: black;
    color: white;
}

.chart_container {
    background-color: white;

}

.client_details {
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.client_notes {
    margin-top: 40px;
}

.list_item {
    padding: 20px;
    background-color: white;
    margin: 10px;
    border-radius: 20px;

}

.list_body {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pending,
.approved {
    opacity: 0.8;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-sizing: border-box;
    border-radius: 20px;
    color: white;
    cursor: pointer;
    font-size: 12px;
    margin-top: 10px;
    display: inline-block;
    color: white;

}

.pending {
    background-color: red;
}

.approved {
    background-color: green;
}

.email_edit {
    display: flex;
    gap: 5px;
    margin-bottom: 5px;

}

.email {
    font-weight: normal;
    margin-right: 5px;
}