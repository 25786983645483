.pending_notes {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

.item {
    flex: 0 !important;
    width: var(--card-width) !important;
}

.carousel_container {
    margin-right: 30px;
    margin-left: 10px;
}

.slider {
    margin-left: 10px;
    display: flex;
    gap: 20px;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
}

.history_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination_container {
    margin-top: 40px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.clients {
    gap: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
}