.container {
    min-height: 300px;
    max-height: 300px;
    min-width: var(--card-width);
    max-width: var(--card-width);
    border-radius: 20px;
    padding: 3vh;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.disabled {
    opacity: 0.1;
}

.body_center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
    opacity: 0.2;
}

.upload_title {
    display: flex;
    gap: 5px;
    align-items: center;
}

.sentiment {
    margin-top: 20px;
}

.body {
    margin-top: 10px;
    margin-bottom: 10px;
    flex: 1;
    margin-bottom: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: gray;
    justify-content: center;
    gap: 5px;
    flex-direction: column;
    flex: 1;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.01);
    border-radius: 10px;
    padding: 10px;
}

.truncate {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    line-clamp: 7;
    overflow: hidden;
}

.action_pill {
    cursor: pointer;
    border-radius: 20px;
    background-color: black;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}