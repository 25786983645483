.container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.loading_container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.row_1 {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-bottom: 40px;
}

.inner_container {
    background-color: rgba(0, 0, 0, 0.01);
    color: grey;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80%;
    padding: 10px;
    border-radius: 10px;
}

.tooltip_highlight {
    color: black;
    font-weight: bold;
    cursor: pointer;
}