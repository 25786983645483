.container {
    flex: 1;
    border-radius: 20px;
    padding: 3vh;
    display: flex;
    flex-direction: column;
    background-color: white;
    transition: all 500ms;
}

.card_body {
    display: flex;
    gap: 20px;
}

.card_image {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}

.spacer {
    flex: 1;
}

.img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
}

.body {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.01);
    border-radius: 10px;
    padding: 5px;
}

.action_pill {
    cursor: pointer;
    border-radius: 20px;
    background-color: black;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}