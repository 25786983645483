.container {
    display: flex;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
}

.sidebar_spacer {
    height: 10px;
}

.center_progress {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.logo_container {
    height: 20%;
}

.logo {
    width: 80%;
    margin-left: 10px;
    border-radius: 100px;
    margin-top: 10px;
}

.body {
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 30px;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.greeting {
    font-size: 40px;
    font-weight: bold;
}

.page_content {
    margin-top: 5vh;
    flex: 1;
}

.header {
    @media (min-width: 700px) {
        position: sticky;
        top: 0;
        z-index: 99999;
        border-radius: 10px;
    }

}

.header_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Adds a soft shadow */

    @media (min-width: 700px) {
        background-color: white;
        padding: 10px;
        border-radius: 10px;
    }
}

.user_profile {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    cursor: pointer;
    opacity: 0.8;
    transition: all 500ms;
}

.user_profile:hover {
    opacity: 1;
}

.dashboard_side_actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 5px;

    @media (max-width: 700px) {
        display: none;
    }
}