.container {
    display: flex;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    padding: 40px;
    flex-direction: column;
}

.body {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: scroll;
}

.title {
    display: flex;
    align-items: center;
    gap: 10px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Hide scrollbar for IE, Edge and Firefox */
.body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.notifications {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.notification {
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 10px;
}