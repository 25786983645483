.App {
  text-align: center;
  background-color: #FAF9F6;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.bold {
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


html {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  background-color: #FAF9F6;

}

html::-webkit-scrollbar {
  display: none;

  /* Safari and Chrome */
}

* {
  font-family: "roboto";
}

/* For WebKit browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;

  /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: white;
  /* Color of the scrollbar */
  border-radius: 20px;
  /* Rounded edges */
}

::-webkit-scrollbar-track {
  /* Transparent track */
  background: rgba(0, 0, 0, 0.05);
  border-radius: 20px;

}

::-webkit-scrollbar-button {
  display: none;
  /* Hides the scroll arrows */
}


*::-moz-scrollbar-button {
  display: none;
  /* Hides the scroll arrows */
}

/* constant */
:root {
  --card-width: 250px;
}

.react-calendar__tile--active {
  background: black !important;
  border-radius: 10px !important;
}

.react-calendar {
  width: 100% !important;
  padding: 40px;
  border: 0px !important;
  border-radius: 30px;
  height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.react-calendar__navigation__label__labelText {
  font-size: 20px;
  color: black;
  font-weight: bolder;

}

.react-calendar__tile--now {
  background: rgba(0, 0, 0, 0.349) !important;
  color: white !important;
  border-radius: 10px !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  border-radius: 10px !important;
}

.react-calendar__tile--hasActive {
  background: black !important;
  border-radius: 10px !important;
  color: white;

}

.react-calendar__navigation button {
  font-size: 30px;
  color: grey;
}

.react-calendar__navigation {
  margin-bottom: 40px !important;
}

.react-calendar__month-view__weekdays__weekday {
  color: grey;
  margin-bottom: 20px;
}

.react-calendar__month-view__weekdays__weekday>* {
  text-decoration: none !important;
}

.react-calendar__tile:disabled {
  opacity: 0.4 !important;
  background-color: transparent !important;
}

.react-calendar__month-view__days__day--weekend {
  color: grey !important;
}

.react-calendar__navigation button:disabled {
  opacity: 0.5;
  background-color: transparent !important;
}

.react-calendar__viewContainer {
  flex: 1;
}

.PhoneInputCountry {
  margin-right: 20px !important;
}


/*.PhoneInputInput {
  border: 0.2px solid grey !important;
  border-radius: 5px !important;
  padding: 15px;
  box-sizing: content-box;
} */