.container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 40vw;
    height: auto;
    border-radius: 100%;
}