.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: rgb(228, 228, 228);
    /* color: white; */
    opacity: 0.6;
    padding: 6px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.5s;
}

.container:hover {
    opacity: 1;
}