.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 5vw;
}

.desc {}

.action {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-top: 20px;
    border-radius: 30px;
}