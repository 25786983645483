.item_container {
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 60px;
    border-radius: 20px;
    background-color: white;
    display: flex;
    align-items: center;
    ;
}

.clipboard {
    position: relative;
    flex: 1;
}

.link {
    color: grey;
    text-decoration: none !important;
}

.link_connect {
    color: grey;
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
    cursor: pointer;
}